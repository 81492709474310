import React from "react";
import Cloud from "./../../assets/img/job/Cloud.webp";
import Dev from "./../../assets/img/job/Devops.webp";
import UIUX from "./../../assets/img/job/UIUX 2.webp";
import Data from "./../../assets/img/job/Data Engineer.webp";
import Full from "./../../assets/img/job/Full Stack.webp";
import { Link } from "react-router-dom";
function Job() {
  return (
    <div className="job">
      <div className="wrapper">
        <div className="main-container">
          <div className="search-type">
            <div className="job-time">
              <div className="job-time-title">Type of Employment</div>
              <div className="job-wrapper">
                <div className="type-container">
                  {/* <input type="checkbox" id="job4" className="job-style" /> */}
                  <label htmlFor="job4">Internship Jobs</label>
                  <span className="job-number">06</span>
                </div>
                <div className="type-container">
                  {/* <input type="checkbox" id="job2" className="job-style" /> */}
                  <label htmlFor="job2">Part Time Jobs</label>
                  <span className="job-number">06</span>
                </div>
                <div className="type-container">
                  {/* <input
                type="checkbox"
                id="job1"
                className="job-style"
                defaultChecked=""
              /> */}
                  <label htmlFor="job1">Full Time Jobs</label>
                  <span className="job-number">0</span>
                </div>

                <div className="type-container">
                  {/* <input type="checkbox" id="job3" className="job-style" /> */}
                  <label htmlFor="job3">Remote Jobs</label>
                  <span className="job-number">06</span>
                </div>
              </div>
            </div>
          </div>
          <div className="searched-jobs">
            <div className="searched-bar">
              <div className="searched-show">Jobs</div>
              {/* <div className="searched-sort">
            Sort by: <span className="post-time">Newest Post </span>
            <span className="menu-icon">▼</span>
          </div> */}
            </div>
            <div className="job-cards">
              <div className="job-card">
                <div className="job-card-header">
                  <img src={Dev} alt="" />
                  <div className="menu-dot" />
                </div>
                <div className="job-card-title">DevOps Engineer</div>
                <div className="job-card-subtitle">
                  We are seeking a highly skilled and proactive DevOps Engineer
                  to join our growing team. As a DevOps Engineer at IT Sight,
                  you will play a crucial role in enhancing our infrastructure,
                  automating processes, and ensuring the seamless deployment and
                  operation of our applications. You will work closely with
                  development, QA, and operations teams to build and maintain
                  robust, scalable, and secure systems that support our business
                  objectives.
                </div>
                <div className="job-detail-buttons">
                  <button className="search-buttons detail-button">
                    Part Time
                  </button>
                  <button className="search-buttons detail-button">
                    Min. 1 Year
                  </button>
                  <button className="search-buttons detail-button">
                    Senior Level
                  </button>
                </div>
                <div className="job-card-buttons">
                  <button className="search-buttons card-buttons">
                    <a href="https://chat.whatsapp.com/Ir5alYhx1I56zATOQY09oX">
                      Apply Now
                    </a>
                  </button>
                </div>
              </div>
              <div className="job-card">
                <div className="job-card-header">
                  <img src={Data} alt="" />
                  <div className="menu-dot" />
                </div>
                <div className="job-card-title">Data Engineer </div>
                <div className="job-card-subtitle">
                  We are seeking a highly skilled and motivated Data Engineer to
                  join our dynamic data team. As a Data Engineer at IT Sight,
                  you will be responsible for building and maintaining the
                  infrastructure and systems that support data collection,
                  transformation, and storage. You will work closely with data
                  scientists, analysts, and other stakeholders to ensure data
                  availability, quality, and usability across the organization.
                </div>
                <div className="job-detail-buttons">
                  <button className="search-buttons detail-button">
                    Part Time
                  </button>
                  <button className="search-buttons detail-button">
                    Min. 1 Year
                  </button>
                  <button className="search-buttons detail-button">
                    Senior Level
                  </button>
                </div>
                <div className="job-card-buttons">
                  <button className="search-buttons card-buttons">
                    <a href="https://chat.whatsapp.com/Ir5alYhx1I56zATOQY09oX">
                      Apply Now
                    </a>
                  </button>
                </div>
              </div>

              <div className="job-card">
                <div className="job-card-header">
                  <img src={Full} alt="" />
                  <div className="menu-dot" />
                </div>
                <div className="job-card-title">Full Stack Developement </div>
                <div className="job-card-subtitle">
                  We are looking for a talented and versatile Full Stack
                  Developer to join our agile development team. As a Full Stack
                  Developer at IT Sight, you will be responsible for designing,
                  developing, and maintaining both the front-end and back-end
                  components of our web applications. You will collaborate
                  closely with product managers, designers, and other developers
                  to create robust and scalable solutions that enhance our user
                  experience and drive business success.
                </div>
                <div className="job-detail-buttons">
                  <button className="search-buttons detail-button">
                    Part Time
                  </button>
                  <button className="search-buttons detail-button">
                    Min. 1 Year
                  </button>
                  <button className="search-buttons detail-button">
                    Senior Level
                  </button>
                </div>
                <div className="job-card-buttons">
                  <button className="search-buttons card-buttons">
                    <a href="https://chat.whatsapp.com/Ir5alYhx1I56zATOQY09oX">
                      Apply Now
                    </a>
                  </button>
                </div>
              </div>
              <div className="job-card">
                <div className="job-card-header">
                  <img src={Cloud} alt="" />
                  <div className="menu-dot" />
                </div>
                <div className="job-card-title">Cloud Engineer </div>
                <div className="job-card-subtitle">
                  We are seeking a highly skilled and motivated Cloud Engineer
                  to join our team. As a Cloud Engineer at IT Sight, you
                  will play a crucial role in designing, implementing, and
                  maintaining cloud-based infrastructure and services. You will
                  work closely with development, operations, and security teams
                  to ensure our cloud environments are scalable, secure, and
                  efficient. Your expertise will help drive our cloud strategy
                  and enable us to deliver high-quality services to our clients.
                </div>
                <div className="job-detail-buttons">
                  <button className="search-buttons detail-button">
                    Part Time
                  </button>
                  <button className="search-buttons detail-button">
                    Min. 1 Year
                  </button>
                  <button className="search-buttons detail-button">
                    Senior Level
                  </button>
                </div>
                <div className="job-card-buttons">
                  <button className="search-buttons card-buttons">
                    <a href="https://chat.whatsapp.com/Ir5alYhx1I56zATOQY09oX">
                      Apply Now
                    </a>
                  </button>
                </div>
              </div>
              <div className="job-card">
                <div className="job-card-header">
                  <img src={UIUX} alt="" />
                  <div className="menu-dot" />
                </div>
                <div className="job-card-title">UI / UX Designer</div>
                <div className="job-card-subtitle">
                  We are seeking a talented and creative UI/UX Designer to join
                  our dynamic team. As a UI/UX Designer at IT Sight, you will
                  play a critical role in shaping the look, feel, and
                  functionality of our products. You will work closely with
                  product managers, developers, and other stakeholders to design
                  intuitive, visually appealing, and user-friendly interfaces
                  that delight our users and drive engagement.
                </div>
                <div className="job-detail-buttons">
                  <button className="search-buttons detail-button">
                    Part Time
                  </button>
                  <button className="search-buttons detail-button">
                    Min. 1 Year
                  </button>
                  <button className="search-buttons detail-button">
                    Senior Level
                  </button>
                </div>
                <div className="job-card-buttons">
                  <button className="search-buttons card-buttons">
                    <a href="https://chat.whatsapp.com/Ir5alYhx1I56zATOQY09oX">
                      Apply Now
                    </a>
                  </button>
                </div>
              </div>
              <div className="job-card">
                <div className="job-card-header">
                  <img src={Data} alt="" />
                  <div className="menu-dot" />
                </div>
                <div className="job-card-title"> AI/ML Developer </div>
                <div className="job-card-subtitle">
                  We are looking for a talented and passionate AI/ML Developer
                  to join our cutting-edge technology team. As an AI/ML
                  Developer at IT Sight, you will play a key role in designing,
                  developing, and deploying advanced machine learning models and
                  AI solutions. You will collaborate with data scientists,
                  engineers, and business stakeholders to create systems that
                  leverage machine learning to solve complex problems and
                  enhance our products and services.
                </div>
                <div className="job-detail-buttons">
                  <button className="search-buttons detail-button">
                    Part Time
                  </button>
                  <button className="search-buttons detail-button">
                    Min. 1 Year
                  </button>
                  <button className="search-buttons detail-button">
                    Senior Level
                  </button>
                </div>
                <div className="job-card-buttons">
                  <button className="search-buttons card-buttons">
                    <a href="https://chat.whatsapp.com/Ir5alYhx1I56zATOQY09oX">
                      Apply Now
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Job;




import React from 'react';
import './style.css';

function Pagenotfound() {
  return (
    <>
      <h1>404</h1>
      
      <div className="info">
        <h2>We can't find that page</h2>
        <p>
          We're fairly sure that page used to be here, but seems to have gone
          missing. We do apologise on its behalf.
        </p>
        <a href="https://itsight.in"  rel="noopener noreferrer">
          Home
        </a>
      </div>
    </>
  )
}

export default Pagenotfound;

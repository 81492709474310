import React from 'react';
import check from '../../assets/img/check.png';
import together from '../../assets/img/together.png';
function Touch() {
  return (
    <section className="gap">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="together">
          <h2>Let’s Thrive Together.</h2>
          <p>
            Contact us and we’ll set up a video call to discuss your
            requirements in detail.
          </p>
          <ul className="list">
            <li>
              <img src={check} alt="check" />
              Application design and Development
            </li>
            <li>
              <img src={check} alt="check" />
              Web &amp; custom app development
            </li>
            <li>
              <img src={check} alt="check" />
              Marketing research &amp; analysis
            </li>
          </ul>
          <a href="/contact" className="btn">
            Get in Touch
          </a>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="together-img">
          <img src={together} alt="Together" />
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Touch

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import client1 from '../../assets/img/clients-1.png';
import client2 from '../../assets/img/clients-2.png';
import client3 from '../../assets/img/clients-3.png';
import client4 from '../../assets/img/clients-4.png';
import client5 from '../../assets/img/clients-5.png';

function Client() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true, // Enable auto scroll
    autoplaySpeed: 2000, // Set auto scroll speed in milliseconds
    responsive: [
      {
        breakpoint: 1024, // width <= 1024px
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600, // width <= 600px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480, // width <= 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can add more breakpoints here
    ]
  };
  return (
    <div className="gap">
      <div className="container">
        <div className="trusted-by">
        <div className="heading">
          {/* <img src={require("../../assets/img/fa.png")} alt="heading-img" /> */}
              <h4>
                Expertise In Global Leading Platforms <br/> <br/> 
              </h4>
          </div>
        </div>
        <Slider {...settings}>
          <div className="partner item">
            <img alt="client-logo" src={client1} />
          </div>
          <div className="partner item">
            <img alt="client-logo" src={client2} />
          </div>
          <div className="partner item">
            <img alt="client-logo" src={client3} />
          </div>
          <div className="partner item">
            <img alt="client-logo" src={client4} />
          </div>
          <div className="partner item">
            <img alt="client-logo" src={client5} />
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default Client;

import React from 'react';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import Banner from './Banner';
function IndustryMain() {
  return (
    <div>
    <Header/>
    <Banner/>

    <Footer/>





    </div>
  )
}

export default IndustryMain;
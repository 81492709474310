import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import quote from '../../assets/img/quote.png';
import user1 from '../../assets/img/quote-1.jpg';
import user2 from '../../assets/img/quote-2.jpg';
import user3 from '../../assets/img/quote-3.jpg';
import user4 from '../../assets/img/quote-4.jpg';
import user5 from '../../assets/img/quote-5.jpg';

function Testimonial() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

  return (
    <section className="gap client-reviews">
  <div className="container">
    <div className="client">
      <div className="client-text">
        <i className="quote">
          <img src={quote} alt="quote" />
        </i>

        <Slider {...settings}>
        <div className="item">
            <ul className="star">
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
            </ul>
            <p>
            AI and ML revolutionized our operations, boosting efficiency and decision-making. With data-driven insights, we've seen significant bottom-line improvements, empowering us to innovate and succeed.Thanks to AI and ML, we're staying ahead of the curve, driving innovation and redefining success in our industry.
            </p>
            <h4>Nalini Bajaj</h4>
            <span>Software Developer</span>
          </div>
          <div className="item">
            <ul className="star">
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
            </ul>
            <p>
            Working with a Big Data consulting firm transformed our business. Their tailored solutions and expert guidance unlocked valuable insights, enhancing our decision-making and efficiency. With their support, we gained a competitive edge and positioned ourselves for sustainable growth.

            </p>
            <h4>Gautam Upadhyay</h4>
            <span>Project Lead</span>
          </div>
          <div className="item">
            <ul className="star">
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
            </ul>
            <p>
            The Design and Architecture team transformed our business with their tailored solutions and expertise. From intuitive designs to scalable infrastructure, they ensured our competitiveness in the digital landscape. Their commitment to excellence made them indispensable partners in our journey towards success.

            </p>
            <h4>Niraj Dixit</h4>
            <span>AI/ML Developer</span>
          </div>
          <div className="item">
            <ul className="star">
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
              <li>
                <i className="fa-solid fa-star" />
              </li>
            </ul>
            <p>
            Cloud & Enterprise Applications have been key to our growth. Their innovative solutions streamlined our operations, boosting efficiency and agility. With their support, we scaled rapidly while cutting costs, making them invaluable partners in our digital transformation journey

            </p>
            <h4>Vikram Gaud</h4>
            <span>Senior Developer</span>
          </div>
        </Slider>
      </div>
    </div>
  </div>
  <img src={user1} className="quote-1" alt="img" />
  <img src={user2} className="quote-2" alt="img" />
  <img src={user3} className="quote-3" alt="img" />
  <img src={user4} className="quote-4" alt="img" />
  <img src={user5} className="quote-5" alt="img" />
</section>

  )
}

export default Testimonial

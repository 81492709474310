import React from 'react';
import { Link } from 'react-router-dom';
import heroimg from '../../assets/img/hero-one-img.png';
function Hero() {
  return (
    <section className="hero-section-one">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="hero-one-text">
          <h1>Modernizing Businesses With Data, Cloud & AI Solutions</h1>
          <h5>
          We help develop solutions for you to solve your business problems with innovation, cutting edge technology which are robust & scalable.
          </h5>
          <div>
            <Link to='/services' className='btn'>Find Out More</Link>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="hero-one-img">
          <img src={heroimg} alt="hero-one-img" />
          <div className="shaps-hero">
            <img
              src="assets/img/shaps-1.png"
              alt="dots-shaps"
              className="shaps-1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  )
}

export default Hero

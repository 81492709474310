import Main from "./pages/Home/Main";
import AboutMain from "./pages/About/AboutMain";
import { BrowserRouter as Router,Routes,Route } from "react-router-dom";
import ContactMain from "./pages/Contact/ContactMain";
import ProductMain from "./pages/Product/ProductMain";
import CareerMain from "./pages/Careers/CareerMain";
import ErrorMain from "./pages/Error Page/ErrorMain";
import Aiml from "./pages/Services/TechServices/AIML";
import DataServices from "./pages/Services/TechServices/DataServices";
import Cloud from "./pages/Services/TechServices/Cloud";
import IndustryMain from "./pages/Industries/IndustryMain";
import './index.css';
function App() {
  return (
    <Router>
       <Routes>
         <Route path="/" element={<Main/>}/>
         <Route path="/about" element={<AboutMain/>}/>
         <Route path="/contact" element={<ContactMain/>}/>
         <Route path="/aiml" element={<Aiml />} />
         <Route path="/dataservice" element={<DataServices />} />
         <Route path="/cloud" element={<Cloud />} />
         <Route path="/product" element={<ProductMain/>}/>
         <Route path="/careers" element={<CareerMain/>}/>
         <Route path="/businessservices" element={<IndustryMain/>}/>
         <Route path="*" element={<ErrorMain/>}/>
         
         
       </Routes>
    </Router>
  );
}

export default App;

import React from 'react';
import Nove from '../../assets/img/chatbot-messenger-.png';
import Crm from '../../assets/img/dashboard-.png';
import Booking from '../../assets/img/appointment.png';
import Bulk from  '../../assets/img/Banner.png';

function Products() {
  return (
    <section className="gap our-projects">
  <div className="container">
    <div className="row">
      <div className="col-lg-6">
        <div className="projects-style">
          <div className="projects-img">
            <figure>
              <img src={Nove} alt="img" />
            </figure>
            <a href="#">
              <i className="fa-solid fa-angle-right" />
            </a>
          </div>
          <h4>
            <a href="#">NOVE - The Intelligent Analyst</a>
          </h4>
          <span>Website — 2023</span>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="projects-style">
          <div className="projects-img">
            <figure>
              <img src={Crm} alt="img" />
            </figure>
            <a href="#">
              <i className="fa-solid fa-angle-right" />
            </a>
          </div>
          <h4>
            <a href="#">IT Sight CRM</a>
          </h4>
          <span>Website — 2023</span>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="projects-style">
          <div className="projects-img">
            <figure>
              <img src={Booking} alt="img" />
            </figure>
            <a href="#">
              <i className="fa-solid fa-angle-right" />
            </a>
          </div>
          <h4>
            <a href="#">Appointment Booking System</a>
          </h4>
          <span>Website — 2023</span>
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="projects-style">
          <div className="projects-img">
            <figure>
              <img src={Bulk} alt="img" />
            </figure>
            <a href="#">
              <i className="fa-solid fa-angle-right" />
            </a>
          </div>
          <h4>
            <a href="#">Bulk Message Sender</a>
          </h4>
          <span>Website — 2023</span>
        </div>
      </div>
      
     
      
    </div>
  </div>
</section>

  )
}

export default Products
import React from 'react';
import Pagenotfound from './Pagenotfound';

function ErrorMain() {
  return (
    <div>
      <Pagenotfound/>
    </div>
  )
}

export default ErrorMain;

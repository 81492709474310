import React from 'react';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import Banner from './Banner';
import BussinessModel from './BussinessModel';
import Client from '../Home/Client';
import Testimonial from '../Home/Testimonial';
import AboutUs from './AboutUs';

function AboutMain() {
  return (
    <div>
        <Header />
        <Banner/>
        {/* <BussinessModel/> */}
        <AboutUs/>
        <Testimonial />
        <Client />
        <Footer />
    </div>
  )
}

export default AboutMain

import React from "react";
import ServiceMainHeading from "./../ServiceMainHeading";
import SubServices from "./../SubServices";
import aivideo from "./../../../assets/video/ai.mp4";
import Header from "../../../component/Header";
import Banner from "../Banner";
import Footer from "../../../component/Footer";
import H2O from "./../../../assets/img/service/aiml/h2o-logo.svg";
import DR from "./../../../assets/img/service/aiml/data-robot.png";
import DataIKU from "./../../../assets/img/service/aiml/Dataiku.png";

function AIML() {
  const servicesArray = [
    {
      id: 2,
      name: 'Our MLOps Capabilities',
      details: [
        {
          detailId: 1,
          title: "H2O",
          imgurl: H2O,
          description: "H2O.ai provides AI and machine learning tools, including H2O-3 for scalable ML, Driverless AI for automation, and H2O Wave for building data apps. Our team specializes in H2O and in implementing solutions tailored to your needs in H2O. Our experts are highly skilled in H2O development."
        },
        {
          detailId: 2,
          title: "Data Robot",
          imgurl: DR,
          description: "DataRobot is specializing in AI and machine learning tools. They offer a platform that automates the end-to-end process of building, deploying, and managing machine learning models. Their suite of products includes automated machine learning, model deployment, and monitoring solutions. Our team specializes in DataRobot and in implementing solutions tailored to your needs in DataRobot. Our experts are highly skilled in DataRobot development."
        },
        {
          detailId: 3,
          title: "DataIKU",
          imgurl: DataIKU,
          description: "Dataiku is specializing in AI and machine learning tools. They offer a comprehensive platform for data science, including features for data preparation, machine learning, and deployment. Their solutions are designed to streamline the entire data science workflow. Our team specializes in Dataiku and in implementing solutions tailored to your needs in Dataiku. Our experts are highly skilled in Dataiku development."
        }
      ]
    }
  ];

  return (
    <>
      <Header />
      <Banner />
      <div className="container my-5">
        <ServiceMainHeading title='AI & ML' videourl={aivideo} />
        <SubServices services={servicesArray} />
      </div>
      <Footer />
    </>
  );
}

export default AIML;

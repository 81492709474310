import React from 'react'

const ServiceMainHeading = (props) => {
  return (
    <div className="row">
        <div className="col-md-6 p-0 text-center order-md-1">
          <video autoPlay muted loop width="100%">
              <source src={props.videourl} type="video/mp4" />
          </video>
        </div>
        <div className="col-md-6 p-4 p-sm-5 order-md-2" style={{ backgroundColor: "#f4f4f4" }}>
          <h1 className="h2 mb-4" style={{ fontWeight: 600, color: "#0066B2" }}>{props.title}</h1>
          <p className="text-secondary" style={{ lineHeight: 1.5, fontSize: 16 }}>Experience the future with our AI & ML services. From intelligent automation to predictive insights, we tailor AI solutions to enhance efficiency, personalize experiences, and ensure security. Stay ahead of the curve, embrace innovation, and transform your business with AI. Partner with us to unlock the potential of AI and drive sustainable growth in the digital age. Let's shape the future together with intelligent technology that empowers your organization to thrive.</p>
        </div>
    </div>
  )
}

export default ServiceMainHeading;

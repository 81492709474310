import React from 'react';
import './SubServiceCards.css';

const SubServiceCards = (props) => {
  return (
    <div className="col-md-6 col-lg-4 pb-3">
      <div className="card card-custom bg-white border-white border-0" style={{ height: '450px' }}>
        <div
          className="card-custom-img"
          style={{ backgroundImage: `url(${props.backgroundImage})` }}
        ></div>
        <div className="card-custom-avatar">
          <img className="img-fluid" src={props.avatarImage} alt={props.title} />
        </div>
        <div className="card-body card-scrollable">
          <h4 className="card-title">{props.title}</h4>
          <p className="card-text">{props.description}</p>
          {props.extraContent && <p className="card-text">{props.extraContent}</p>}
        </div>
      </div>
    </div>
  );
};

export default SubServiceCards;

import React from 'react';
import heading from '../../assets/img/fa.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import project1 from '../../assets/img/chatbot-messenger-.png';
import project2 from '../../assets/img/appointment.png';
import project3 from '../../assets/img/Banner.png';
import business from '../../assets/img/business.png';
import shape from '../../assets/img/dots-shaps.png';
import Dashboard from '../../assets/img/dashboard-.png';


function Project() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay:true,
        responsive: [
            {
              breakpoint: 992, // width <= 1024px
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 768, // width <= 1024px
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
                breakpoint: 480, // width <= 1024px
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              }
          ]
      };
    return (
        <section className="gap no-bottom projects">
            <div className="container">
                <div className="heading">
                    {/* <img src={heading} alt="heading-img" /> */}
                    <h6 style={{ fontSize: '35px' }}>Products</h6>
                    <h2>Discover Endless Options In Our Diverse Product Range</h2>
                </div>

                <Slider {...settings}>
                <div className="col-lg-12 item">
                        <div className="projects-style">
                            <div className="projects-img">
                                <figure>
                                    <img src={project1} alt="img" />
                                </figure>
                                <a href="/product">
                                    <i className="fa-solid fa-angle-right" />
                                </a>
                            </div>
                            <h4>
                                <a href="/product">NOVE - The Intelligent Analyst</a>
                            </h4>
                            <span>Website — 2023</span>
                        </div>
                    </div>
                    <div className="col-lg-12 item">
                        <div className="projects-style">
                            <div className="projects-img">
                                <figure>
                                    <img src={Dashboard} alt="img" />
                                </figure>
                                <a href="/product">
                                    <i className="fa-solid fa-angle-right" />
                                </a>
                            </div>
                            <h4>
                                <a href="/product">IT Sight CRM.</a>
                            </h4>
                            <span>Website — 2023</span>
                        </div>
                    </div>
                    <div className="col-lg-12 item">
                        <div className="projects-style">
                            <div className="projects-img">
                                <figure>
                                    <img src={project2} alt="img" />
                                </figure>
                                <a href="project-details-1.html">
                                    <i className="fa-solid fa-angle-right" />
                                </a>
                            </div>
                            <h4>
                                <a href="/product">Appointment Booking System</a>
                            </h4>
                            <span>Website — 2023</span>
                        </div>
                    </div>
                    <div className="col-lg-12 item">
                        <div className="projects-style">
                            <div className="projects-img">
                                <figure>
                                    <img src={project3} alt="img" />
                                </figure>
                                <a href="project-details-1.html">
                                    <i className="fa-solid fa-angle-right" />
                                </a>
                            </div>
                            <h4>
                                <a href="/product">Bulk Message Sender</a>
                            </h4>
                            <span>Website — 2023</span>
                        </div>
                    </div>
                </Slider>
            </div>
            <div className="gap">
            </div>
        </section>

    )
}

export default Project
